// src/components/PredictiveSearch.js
import React, { useState } from 'react';
import { Box, Input, List, ListItem } from '@chakra-ui/react';
import craftingRecipes from '../data/craftingRecipes';

const PredictiveSearch = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleInputChange = (value) => {
    setInputValue(value);
    onChange(value);
    setFilteredItems(
      Object.keys(craftingRecipes).filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <Box position="relative" w="full">
      <Input
        placeholder="Search for an item..."
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        focusBorderColor="orange.400"
        fontSize="md"
        fontWeight="normal"
      />
      {inputValue && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          w="full"
          bg="gray.700"
          borderRadius="md"
          boxShadow="md"
          zIndex="10"
          mt="2"
        >
          <List spacing="0">
            {filteredItems.map((item) => (
              <ListItem
                key={item}
                p="2"
                cursor="pointer"
                _hover={{ bg: 'gray.600' }}
                onClick={() => {
                  setInputValue(item);
                  onChange(item);
                  setFilteredItems([]);
                }}
              >
                {item}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default PredictiveSearch;
