import React, { useState } from 'react';
import { Box, VStack, Select, HStack, Text, Input } from '@chakra-ui/react';

const tools = {
  hatchet: 100,
  pickaxe: 200,
  stoneHatchet: 50,
  stonePickaxe: 75,
  salvagedAxe: 150,
  salvagedIcepick: 175,
};

const DurabilityCalculator = () => {
  const [tool, setTool] = useState('');
  const [useCount, setUseCount] = useState(1);

  const getDurability = (tool, useCount) => {
    return tools[tool] ? tools[tool] - useCount : 0;
  };

  const remainingDurability = getDurability(tool, useCount);

  return (
    <Box>
      <VStack spacing="4">
        <Select placeholder="Select tool" value={tool} onChange={(e) => setTool(e.target.value)}>
          {Object.keys(tools).map(tool => (
            <option key={tool} value={tool}>{tool}</option>
          ))}
        </Select>
        <HStack spacing="2">
          <Text>Uses:</Text>
          <Input
            type="number"
            value={useCount}
            onChange={(e) => setUseCount(parseInt(e.target.value) || 0)}
            focusBorderColor="orange.400"
          />
        </HStack>
        <Text>Remaining Durability: {remainingDurability}</Text>
      </VStack>
    </Box>
  );
};

export default DurabilityCalculator;
