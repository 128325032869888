// src/components/Auth.js
import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Button, Input, VStack, Heading } from '@chakra-ui/react';

const Auth = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      console.error('Error during sign up:', error);
    }
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      console.error('Error during sign in:', error);
    }
  };

  return (
    <VStack spacing="4">
      <Heading as="h2" size="lg">Sign In / Sign Up</Heading>
      <Input
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        size="md"
        focusBorderColor="orange.400"
      />
      <Input
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        size="md"
        focusBorderColor="orange.400"
      />
      <Button onClick={handleSignUp} colorScheme="orange">Sign Up</Button>
      <Button onClick={handleSignIn} colorScheme="orange">Sign In</Button>
    </VStack>
  );
};

export default Auth;
