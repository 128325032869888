const craftingRecipes = {
  rocket: {
    quantity: 1,
    icon: 'https://wiki.rustclash.com/img/items180/ammo.rocket.basic.png',
    ingredients: {
      explosives: 10,
      metalPipe: 2,
      gunpowder: 150,
    },
    craftingTime: {
      vanilla: 30,
      x2: 15,
    },
  },
  explosives: {
    quantity: 1,
    icon: 'https://wiki.rustclash.com/img/items180/explosives.png',
    ingredients: {
      gunpowder: 50,
      sulfur: 10,
      lowGrade: 3,
      metalFrags: 10,
    },
    craftingTime: {
      vanilla: 10,
      x2: 5,
    },
  },
  gunpowder: {
    quantity: 1,
    icon: 'https://wiki.rustclash.com/img/items180/gunpowder.png',
    ingredients: {
      charcoal: 3,
      sulfur: 2,
    },
    alternate: {
      mixingTable: {
        ingredients: {
          charcoal: 2,
          sulfur: 2,
        },
        craftingTime: {
          vanilla: 1,
          x2: 0.5,
        },
      },
      byHand: {
        ingredients: {
          charcoal: 3,
          sulfur: 2,
        },
        craftingTime: {
          vanilla: 2,
          x2: 1,
        },
      },
    },
    craftingTime: {
      vanilla: 2,
      x2: 1,
    },
  },
  explosiveAmmo: {
    quantity: 2,
    icon: 'https://wiki.rustclash.com/img/items180/ammo.rifle.explosive.png',
    ingredients: {
      metalFrags: 10,
      gunpowder: 20,
      sulfur: 10,
    },
    craftingTime: {
      vanilla: 5,
      x2: 2.5,
    },
  },
  c4: {
    quantity: 1,
    icon: 'https://wiki.rustclash.com/img/items180/explosive.timed.png',
    ingredients: {
      explosives: 20,
      cloth: 5,
      techTrash: 2,
    },
    craftingTime: {
      vanilla: 60,
      x2: 30,
    },
  },
  // Non-craftable items
  cloth: {
    icon: 'https://wiki.rustclash.com/img/items180/cloth.png',
  },
  metalPipe: {
    icon: 'https://wiki.rustclash.com/img/items180/metalpipe.png',
  },
  sulfur: {
    icon: 'https://wiki.rustclash.com/img/items180/sulfur.png',
  },
  lowGrade: {
    icon: 'https://wiki.rustclash.com/img/items180/lowgradefuel.png',
  },
  metalFrags: {
    icon: 'https://wiki.rustclash.com/img/items180/metal.fragments.png',
  },
  charcoal: {
    icon: 'https://wiki.rustclash.com/img/items180/charcoal.png',
  },
  techTrash: {
    icon: 'https://wiki.rustclash.com/img/items180/techparts.png',
  },
};

export default craftingRecipes;
