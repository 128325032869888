// src/components/CommunityContributions.js
import React, { useState } from 'react';
import { Box, VStack, Input, Textarea, Button } from '@chakra-ui/react';

const CommunityContributions = () => {
  const [contribution, setContribution] = useState('');

  const handleContributionChange = (e) => {
    setContribution(e.target.value);
  };

  const handleContributionSubmit = () => {
    // Submit the contribution to a backend or database
    console.log('Contribution submitted:', contribution);
  };

  return (
    <Box>
      <VStack spacing="4">
        <Textarea
          placeholder="Share your recipe or crafting tip..."
          value={contribution}
          onChange={handleContributionChange}
          size="lg"
          focusBorderColor="orange.400"
        />
        <Button onClick={handleContributionSubmit} colorScheme="orange">Submit</Button>
      </VStack>
    </Box>
  );
};

export default CommunityContributions;
