import React, { useState } from 'react';
import { Box, VStack, Select, HStack, Image, Text } from '@chakra-ui/react';
import craftingRecipes from '../data/craftingRecipes';

const ItemComparison = () => {
  const [firstItem, setFirstItem] = useState('');
  const [secondItem, setSecondItem] = useState('');

  const getCraftingTime = (item) => {
    const recipe = craftingRecipes[item];
    if (!recipe) return { vanilla: 0, x2: 0 };

    return recipe.craftingTime || { vanilla: 0, x2: 0 };
  };

  const getIngredients = (item) => {
    const recipe = craftingRecipes[item];
    if (!recipe || !recipe.ingredients) return {};

    return recipe.ingredients;
  };

  const renderIngredients = (ingredients) => {
    return Object.entries(ingredients).map(([ingredient, amount]) => (
      <HStack key={ingredient} spacing="2">
        <Image
          src={craftingRecipes[ingredient]?.icon || ''}
          alt={ingredient}
          boxSize="6"
        />
        <Text>{ingredient}: {amount}</Text>
      </HStack>
    ));
  };

  const firstItemIngredients = getIngredients(firstItem);
  const secondItemIngredients = getIngredients(secondItem);

  return (
    <Box>
      <VStack spacing="4">
        <Select placeholder="Select first item" value={firstItem} onChange={(e) => setFirstItem(e.target.value)}>
          {Object.keys(craftingRecipes).map(item => (
            <option key={item} value={item}>{item}</option>
          ))}
        </Select>
        <Select placeholder="Select second item" value={secondItem} onChange={(e) => setSecondItem(e.target.value)}>
          {Object.keys(craftingRecipes).map(item => (
            <option key={item} value={item}>{item}</option>
          ))}
        </Select>
      </VStack>
      <HStack spacing="8" mt="8">
        <VStack spacing="2">
          <Text fontWeight="bold">{firstItem}</Text>
          <Text>Crafting Time (Vanilla): {getCraftingTime(firstItem).vanilla} seconds</Text>
          <Text>Crafting Time (2x): {getCraftingTime(firstItem).x2} seconds</Text>
          {renderIngredients(firstItemIngredients)}
        </VStack>
        <VStack spacing="2">
          <Text fontWeight="bold">{secondItem}</Text>
          <Text>Crafting Time (Vanilla): {getCraftingTime(secondItem).vanilla} seconds</Text>
          <Text>Crafting Time (2x): {getCraftingTime(secondItem).x2} seconds</Text>
          {renderIngredients(secondItemIngredients)}
        </VStack>
      </HStack>
    </Box>
  );
};

export default ItemComparison;
