import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  extendTheme,
  Heading,
  Flex,
  Button,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Input,
  HStack,
  Image,
  Text,
  Select,
  Spacer,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { calculateIngredients } from './utils/calculateIngredients';
import IngredientTree from './components/IngredientTree';
import PredictiveSearch from './components/PredictiveSearch';
import ItemComparison from './components/ItemComparison';
import DurabilityCalculator from './components/DurabilityCalculator';
import CommunityContributions from './components/CommunityContributions';
import Auth from './components/Auth';
import craftingRecipes from './data/craftingRecipes';
import { auth } from './firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? '#212121' : 'white',
        color: props.colorMode === 'dark' ? 'white' : 'black',
      },
    }),
  },
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
  },
  components: {
    Select: {
      baseStyle: {
        field: {
          bg: 'gray.700',
          color: 'white',
        },
      },
      variants: {
        filled: (props) => ({
          field: {
            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.200',
            color: props.colorMode === 'dark' ? 'white' : 'black',
            _hover: {
              bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.300',
            },
            _focus: {
              bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.300',
            },
          },
        }),
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Menu: {
      baseStyle: (props) => ({
        list: {
          bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.200',
          color: props.colorMode === 'dark' ? 'white' : 'black',
        },
        item: {
          _hover: {
            bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.300',
          },
        },
      }),
    },
  },
});

const MotionBox = motion(Box);

const App = () => {
  const [user, setUser] = useState(null);
  const [item, setItem] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [calculatedItem, setCalculatedItem] = useState('');
  const [calculatedQuantity, setCalculatedQuantity] = useState(1);
  const [ingredients, setIngredients] = useState({});
  const [isSearched, setIsSearched] = useState(false);
  const [serverType, setServerType] = useState('vanilla');
  const [useAlternate, setUseAlternate] = useState('normal');
  const [currentResources, setCurrentResources] = useState({});
  const [craftingQueue, setCraftingQueue] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSearch = () => {
    setIsSearched(true);
    setCalculatedItem(item);
    setCalculatedQuantity(quantity);
    const requiredIngredients = calculateIngredients(item, quantity, useAlternate, currentResources);
    setIngredients(requiredIngredients);
    toast('Crafting calculation completed!');
  };

  const handleResourceChange = (resource, amount) => {
    setCurrentResources({
      ...currentResources,
      [resource]: parseInt(amount) || 0,
    });
  };

  const addToQueue = () => {
    setCraftingQueue([
      ...craftingQueue,
      { item, quantity, useAlternate },
    ]);
    setItem('');
    setQuantity(1);
    setUseAlternate('normal');
  };

  const getTotalCraftingTime = () => {
    return craftingQueue.reduce((total, { item, quantity, useAlternate }) => {
      return total + getCraftingTime(item, quantity, serverType, useAlternate);
    }, 0);
  };

  const getCraftingTime = (item, quantity, serverType, useAlternate) => {
    const recipe = craftingRecipes[item];
    if (!recipe) return 0;

    let craftingTime;
    if (useAlternate !== 'normal' && recipe.alternate) {
      craftingTime = recipe.alternate[useAlternate].craftingTime[serverType];
    } else {
      craftingTime = recipe.craftingTime[serverType];
    }

    return craftingTime ? craftingTime * quantity : 0;
  };

  const craftingTime = getCraftingTime(calculatedItem, calculatedQuantity, serverType, useAlternate);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const getEfficiencyTips = (item, quantity) => {
    const tips = [];
    const recipe = craftingRecipes[item];
    if (!recipe) return tips;

    if (recipe.alternate) {
      if (recipe.alternate.mixingTable && recipe.alternate.mixingTable.craftingTime.vanilla < recipe.craftingTime.vanilla) {
        tips.push('Use Mixing Table for faster crafting.');
      }
    }

    if (recipe.ingredients) {
      for (const [ingredient, amount] of Object.entries(recipe.ingredients)) {
        if (amount > 10) {
          tips.push(`Consider gathering more ${ingredient} in bulk to save time.`);
        }
      }
    }

    return tips;
  };

  const efficiencyTips = getEfficiencyTips(calculatedItem, calculatedQuantity);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleLogout = () => {
    auth.signOut();
    setUser(null);
  };

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer />
      <Flex
        minH="100vh"
        direction="column"
        bgGradient={isDarkMode ? 'linear(to-r, #232526, #414345)' : 'linear(to-r, #FFFFFF, #E0E0E0)'}
      >
        <Flex w="full" justify="flex-end" p="4">
          <Button onClick={toggleDarkMode} mr="4">
            Toggle {isDarkMode ? 'Light' : 'Dark'} Mode
          </Button>
          {user && (
            <Menu>
              <MenuButton as={Button} background="transparent">
                <Avatar size="sm" name={user.email} />
              </MenuButton>
              <MenuList>
                <MenuItem>Signed in as {user.email}</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Flex w="full" justify="center" align="center" mb="4">
          <Heading as="h1" size="2xl" textAlign="center">
            Rust Crafting Calculator
          </Heading>
        </Flex>
        <Tabs variant="soft-rounded" colorScheme="orange" w="full" maxW="md" mt="4" onChange={handleTabChange} alignSelf="center">
          <TabList mb="4">
            <Tab>Calculator</Tab>
            <Tab>Comparison</Tab>
            <Tab>Durability</Tab>
            <Tab>Queue</Tab>
            <Tab>Contributions</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack spacing="4" align="center" w="full">
                <PredictiveSearch value={item} onChange={setItem} />
                <Input
                  type="number"
                  placeholder="Quantity"
                  size="lg"
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value) || '')}
                  focusBorderColor="orange.400"
                  mt="4"
                  fontSize="md"
                  fontWeight="normal"
                />
                <Select
                  placeholder="Select server type"
                  value={serverType}
                  onChange={(e) => setServerType(e.target.value)}
                  focusBorderColor="orange.400"
                  mt="4"
                  fontSize="md"
                  fontWeight="normal"
                >
                  <option value="vanilla">Vanilla</option>
                  <option value="x2">2x</option>
                </Select>
                <Select
                  placeholder="Select recipe type"
                  value={useAlternate}
                  onChange={(e) => setUseAlternate(e.target.value)}
                  focusBorderColor="orange.400"
                  mt="4"
                  fontSize="md"
                  fontWeight="normal"
                >
                  <option value="normal">Normal</option>
                  <option value="mixingTable">Mixing table</option>
                  <option value="byHand">By Hand</option>
                </Select>
                <Button onClick={handleSearch} size="lg" mt="4">
                  Calculate
                </Button>
              </VStack>
              {isSearched && (
                <MotionBox
                  w="full"
                  maxW="5xl"
                  mt="8"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Flex direction="row" w="full" justifyContent="space-between">
                    <Box flex="1" pr="4" mr="2" textAlign="right">
                      <Heading as="h2" size="lg" mb="4">
                        Total Ingredients:
                      </Heading>
                      <VStack align="end" spacing="2">
                        {Object.entries(ingredients).map(([ingredient, amount]) => (
                          <HStack key={ingredient} spacing="2">
                            <Image
                              src={craftingRecipes[ingredient]?.icon || ''}
                              alt={ingredient}
                              boxSize="6"
                            />
                            <Text>{ingredient}: {amount}</Text>
                          </HStack>
                        ))}
                      </VStack>
                    </Box>
                    <Box flex="1" pl="4" ml="2" textAlign="left">
                      <Heading as="h2" size="lg" mb="4">
                        Ingredients:
                      </Heading>
                      <IngredientTree item={calculatedItem} quantity={calculatedQuantity} />
                    </Box>
                  </Flex>
                  <Heading as="h2" size="lg" mt="8" textAlign="center">
                    Total Crafting Time: {craftingTime} seconds
                  </Heading>
                  {efficiencyTips.length > 0 && (
                    <Box mt="8">
                      <Heading as="h2" size="lg" textAlign="center">
                        Efficiency Tips
                      </Heading>
                      <VStack align="start" spacing="2" mt="4">
                        {efficiencyTips.map((tip, index) => (
                          <Text key={index}>{tip}</Text>
                        ))}
                      </VStack>
                    </Box>
                  )}
                </MotionBox>
              )}
            </TabPanel>
            <TabPanel>
              <ItemComparison />
            </TabPanel>
            <TabPanel>
              <DurabilityCalculator />
            </TabPanel>
            <TabPanel>
              <VStack spacing="4" align="center" w="full" mt="4">
                <Heading as="h2" size="lg">Crafting Queue</Heading>
                <Button onClick={addToQueue} size="lg" mt="4">
                  Add to Queue
                </Button>
                <VStack spacing="4" align="center" w="full" mt="4">
                  <Heading as="h2" size="lg">Current Resources</Heading>
                  {Object.keys(craftingRecipes).map(resource => (
                    craftingRecipes[resource].icon && (
                      <HStack key={resource} spacing="2">
                        <Image
                          src={craftingRecipes[resource]?.icon || ''}
                          alt={resource}
                          boxSize="6"
                        />
                        <Text>{resource}:</Text>
                        <Input
                          type="number"
                          size="md"
                          placeholder="Amount"
                          value={currentResources[resource] || ''}
                          onChange={(e) => handleResourceChange(resource, e.target.value)}
                          focusBorderColor="orange.400"
                        />
                      </HStack>
                    )
                  ))}
                </VStack>
                {craftingQueue.map((queueItem, index) => (
                  <HStack key={index} spacing="2">
                    <Text>{queueItem.item}: {queueItem.quantity}</Text>
                    <Button size="sm" colorScheme="red" onClick={() => setCraftingQueue(craftingQueue.filter((_, i) => i !== index))}>Remove</Button>
                  </HStack>
                ))}
                <Heading as="h3" size="md" mt="4">Total Crafting Time: {getTotalCraftingTime()} seconds</Heading>
              </VStack>
            </TabPanel>
            <TabPanel>
              {user ? (
                <CommunityContributions />
              ) : (
                <Auth setUser={setUser} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex position="absolute" bottom="4" left="4" align="center">
          <Text mr="2">Sponsored By LastSurvivors.co</Text>
          <Avatar size="sm" src="https://lastsurvivors.co/wp-content/uploads/2024/07/111image.png" /> 
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};

export default App;
