// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDWF8mGZ2mTaAefKlxyLirSExLtAKkTcSQ",
    authDomain: "rustcraftcalc.firebaseapp.com",
    projectId: "rustcraftcalc",
    storageBucket: "rustcraftcalc.appspot.com",
    messagingSenderId: "20823808503",
    appId: "1:20823808503:web:2ff21313b4e1aaf1a036f2",
    measurementId: "G-D3M1T4C7BT"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
  
export { auth, firestore };