// src/utils/calculateIngredients.js
import craftingRecipes from '../data/craftingRecipes';

export const calculateIngredients = (item, quantity, useAlternate = 'normal', currentResources = {}) => {
  const result = {};

  const recurse = (item, quantity) => {
    const recipe = craftingRecipes[item];
    if (!recipe) return;

    let selectedRecipe = recipe;
    if (useAlternate !== 'normal' && recipe.alternate) {
      selectedRecipe = recipe.alternate[useAlternate];
    }

    if (!selectedRecipe || !selectedRecipe.ingredients) {
      result[item] = (result[item] || 0) + quantity;
      return;
    }

    for (const [ingredient, amount] of Object.entries(selectedRecipe.ingredients)) {
      const totalAmount = amount * quantity;
      const availableAmount = currentResources[ingredient] || 0;
      const requiredAmount = Math.max(totalAmount - availableAmount, 0);

      recurse(ingredient, requiredAmount);
    }
  };

  recurse(item, quantity);
  return result;
};
