import React from 'react';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import craftingRecipes from '../data/craftingRecipes';

const MotionVStack = motion(VStack);

const IngredientTree = ({ item, quantity }) => {
  const recipe = craftingRecipes[item];
  const iconPath = recipe?.icon || '';

  if (!recipe || !recipe.ingredients) {
    return (
      <HStack spacing="2" alignItems="start">
        {iconPath && <Image src={iconPath} alt={item} boxSize="6" />}
        <Text>{item}: {quantity}</Text>
      </HStack>
    );
  }

  const ingredients = Object.entries(recipe.ingredients).map(([ingredient, amount]) => (
    <IngredientTree key={ingredient} item={ingredient} quantity={amount * quantity} />
  ));

  return (
    <MotionVStack
      align="start"
      spacing="2"
      borderLeft="2px"
      borderColor="orange.400"
      pl="4"
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <HStack spacing="2">
        {iconPath && <Image src={iconPath} alt={item} boxSize="6" />}
        <Text>{item}: {quantity}</Text>
      </HStack>
      <Box ml="4">
        {ingredients}
      </Box>
    </MotionVStack>
  );
};

export default IngredientTree;
